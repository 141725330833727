import React, { useReducer } from "react";
import axios from "axios";
import history from "../../utilities/history";
import moment from "moment";
import mainContext from "./mainContext";
import mainReducer from "./mainReducer";
// import setAuthToken from "../../utilities/setAuthToken";
import {
  CHANGE_QR_TYPE,
  CHANGE_QR_DATA,
  CHANGE_QR_DATA_EXTRA_FIELD,
  CHANGE_QR_COLOR,
  CHANGE_QR_COLOR2,
  CHANGE_QR_LOGO,
  TOGGLE_QR_LOGO,
  CHANGE_QR_BORDER,
  TOGGLE_QR_LOADING,
  TOGGLE_QR_GENERATED,
  QR_LOADED,
  SIGN_IN_SUCCESS_NA,
  SIGN_OUT,
  QR_LOAD_2_COLLECTION,
  QR_ADDED_2_COLLECTION,
  QR_LISTS_LOADED,
  CHANGE_QR_STYLE,
  CHANGE_QR_BACKGROUND,
  TRIGGER_ACC_POPUP,
  QR_ARCHIVED,
  GET_LAST_7_DAYS,
  GET_ALL_POPS,
  GET_LAST_30_DAYS,
  GET_LAST_365_DAYS,
  TRIGGER_SNACKBAR,
  TOGGLE_CREATE_POP_UP,
  TOGGLE_SUB_POP_UP,
  SET_STRIPE_CUSTOMER_NA,
  SET_PRO_SUCCESS,
  CHANGE_QR_LOGO_FILE,
  CHANGE_QR_LOGO_URL,
  QR_URL_CHANGE,
  QR_NAME_CHANGE,
  TOGGLE_CONTACT_POP_UP,
  SINGLE_QR_LOADED,
  QR_NAMES_CHANGE,
  TOGGLE_VIEWSCAN_POP_UP,
  GET_COMPARER_POPS,
  BLOGS_LOADED,
  SET_POPL_CARD,
  CLEAR_POPL_CARD,
  AI_QR_LOADED,
  CHANGE_AI_QR_LOGO_FILE,
  CHANGE_AI_QR_LOGO_URL,
  AI_QR_CLEARED,
  TOGGLE_FROM_AI_BTN
} from "./types";
import firebase from "../firebase.config";
import { setQrLogo, signInWithEmailPassword, uploadImage } from "../firebase.query";
import { axiosInstance, axiosInstanceApp, axiosInstanceQR, axiosInstanceV3 } from "../../utilities/axios.config";
import { getId } from "../../collections-page/uniqueId";
import { slackBot } from "../../utilities/slackBot";
import { Button } from "@material-ui/core";
import { getRecaptchaToken } from "../../utilities/recaptcha";

// import mixpanel from "mixpanel-browser";

// mixpanel.init("d8bff050e9b371d995bd05c9f13e77d4", { ignore_dnt: true });

const MainState = props => {
  const initialState = {
    user: null,
    snackbar: null,
    stripeCustomer: null,
    subscribePopUp: false,
    viewScanpopup: false,
    contactPopUp: false,
    createAccPopUp: false,
    createAccPopUpMessage: "Create an account to continue",
    savedQRs: [],
    last7Data: null,
    last30Data: null,
    last365Data: null,
    allTimeData: null,
    comparerData: null,
    QRloading: false,
    QRgenerated: false,
    QRinCollection: false,
    QRisSavable: false,
    QRtoCollectionLoader: false,
    QRid: "",
    QRtypeOptions: [
      "Website URL",
      "AI Generated",
      // "Popl Card",
      "Dynamic",
      // "Contact Info",
      // "Instagram",
      // "Facebook",
      // "Twitter",
      // "YouTube",
      // "Wifi",
      // "Phone Number",
      // "Google Search",
      "Other",
    ],
    QRtype: "Website URL",
    QRcolor: "#000000",
    QRcolor2: "#000000",
    QRbackground: "#ffffff",
    QRlogo: false,
    QRlogoFile: null,
    QRlogoUrl: "",
    QRborder: "Rounded",
    QRstyle: "Squared",
    QRanalytics: false,
    QRredirectURL: "",
    QRdata: "",
    QRdataExtraField1: "",
    QRdataExtraField2: "",
    QRdataExtraField3: "",
    QRdataExtraField4: "",
    QRdataExtraField5: "",
    QRdataExtraField6: "",
    QRdataExtraField7: "",
    QRdataExtraField8: "",
    QRdataExtraField9: "",
    QRdataExtraField10: "",
    singleQR: null,
    fromAiQR: false,
    poplCardData: null,
    comparisonData: null,
    optionalDownload: "",
    blogs: [],
    aiQRlogoFile: null,
    aiQRlogoURL: "",
    aiQRs: []
  };

  const [state, dispatch] = useReducer(mainReducer, initialState);

  // Functions

  const triggerSnackbar = (text, severity, isBtn, btnText, btnLink) => {
    dispatch({
      type: TRIGGER_SNACKBAR,
      snackbar: {
        text: isBtn ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <span>{text}</span>
            <Button
              className="contact-us2"
              target="blank"
              href={btnLink}
            >
              {btnText}
            </Button>
          </div>
        ) : text,
        severity: severity,
      }
    });

    setTimeout(() =>
      dispatch({
        type: TRIGGER_SNACKBAR,
        snackbar: null,
      }), 5000
    )
  };

  // Auth

  const setTokens = ({ idToken, refreshToken }) => {
    localStorage.setItem("@qrToken", idToken);
    localStorage.setItem("@qrRefreshToken", refreshToken);
  };

  const authHandler = () => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        dispatch({
          type: TOGGLE_CREATE_POP_UP
        });
      } else {
        console.log("user is signed out");
        localStorage.removeItem("@qrToken");
        localStorage.removeItem("@qrRefreshToken");
        dispatch({
          type: SIGN_OUT,
        });
      }
    });
  };

  const logout = (cb) => firebase.auth().signOut().then(() => {
    triggerSnackbar("Successfully logged out", "success");
    localStorage.removeItem("@qrToken");
    localStorage.removeItem("@qrRefreshToken");
    dispatch({
      type: SIGN_OUT,
    });
    cb();
  }).catch((error) => {
  });

  const signUpWithToken = async (token) => {
    const recaptchaToken = await getRecaptchaToken();
    const response = await axiosInstance("/auth/signup", {
      method: "POST",
      header: {
        Authorization: token,
      },
      data: {
        authMethod: "email",
        qrg: true,
        recaptchaToken
      },
    })

    console.log(response);
  };

  const signUpWithEmailPassword = async (email, password, cb) => {
    try {
      const userCredential = await firebase.auth().createUserWithEmailAndPassword(email, password);
      const { user } = userCredential;
      user.getIdToken().then((token) => {
        cb(null, { idToken: token, refreshToken: user.refreshToken });
      });
    } catch (error) {
      console.log(error);
      cb(error);
    }
  };

  const getUserProfileAction = async (fromSignin, cb = () => { }) => {
    try {
      // dispatch(fetchingAction(true));
      const parentProfile = await axiosInstance("/profile?skipOpenDate=true", {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("@qrToken"),
        }
      }).then((res) => res.data.data);

      dispatch({
        type: SIGN_IN_SUCCESS_NA,
        payload: parentProfile,
      });

      cb();

      if (fromSignin) {
        triggerSnackbar("Successfully signed in", "success");
      }

      // const connections = getState().connectionReducer_NA.connections.connectionsObject;
    } catch (error) {
      if (fromSignin) {
        triggerSnackbar("Incorrect login", "warning");
        cb();
      }
      console.log(error);
    }
  };

  const signIn = async (email, password, onClose, stopLoader) => {
    try {
      const token = await signInWithEmailPassword({ email: email, password: password, returnSecureToken: true });
      setTokens({ idToken: token });
      getUserProfileAction(true, onClose);
    } catch (error) {
      if (error.code === "auth/user-not-found" || error.code === "auth/wrong-password") {
        triggerSnackbar("Invalid credentials", "error");
        stopLoader();
      } else {
        try {
          await signUpWithToken(localStorage.getItem("@qrToken"));
          getUserProfileAction();
        } catch (err) {
          console.log(err);
          alert(err);
        }
      }
    }
  };

  const signUp = async (email, password, cb) => {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    try {
      console.log("here 1");
      signUpWithEmailPassword(email, password, async (error, tokens) => {
        if (error) {
          return cb(error);
        }
        await signUpWithToken(tokens.idToken);
        await axios.post("/api/qrcode/slackbot", { type: "New user", payload: email }, config);
        setTokens(tokens);
        getUserProfileAction(true, cb);
      });
    } catch (error) {
      console.log("error");
      triggerSnackbar("Server Error. Please reload the dashboard and try again. If problem persists, please contact support@popl.co", "error");
    }
  };

  const toggleCreateAcc = (bool, msg = "Create an account to continue", optionalDownload = "") => {
    dispatch({ type: TRIGGER_ACC_POPUP, payload: bool, msg: msg, optionalDownload: optionalDownload });
  };

  const toggleSubscribePopUp = (bool) => {
    dispatch({ type: TOGGLE_SUB_POP_UP, payload: bool });
  };

  const toggleViewScanPopUp = (bool) => {
    dispatch({ type: TOGGLE_VIEWSCAN_POP_UP, payload: bool });
  };

  const toggleContactPopUp = (bool) => {
    dispatch({ type: TOGGLE_CONTACT_POP_UP, payload: bool });
  };

  const sendMessage = async (message, messageSubject, cb) => {
    await slackBot("Message", state?.user?.email, message, messageSubject);
    cb();
    triggerSnackbar(
      messageSubject === "Help" ?
        "Message sent! We'll do our best to follow up shortly"
        : messageSubject === "Feature request" ?
          "Request sent!"
          : "Message sent!"
      , "success"
    );
  };

  // QR

  const triggerSlack = async (type, payload) => {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    try {
      await axios.post("/api/qrcode/slackbot", { type: type, payload: payload }, config);
    } catch (error) {
      console.log("error");
    }
  };

  const toggleQRloading = type => {
    dispatch({ type: TOGGLE_QR_LOADING, payload: type });
  };

  const onGenerate = async (isPoplCard) => {
    if (state.QRtype === "Dynamic" && state.user == null) return toggleCreateAcc(true);
    if (state.QRtype === "Dynamic" && state?.user?.isPro != "1" && state?.user?.isPro != "2") return toggleSubscribePopUp(true);

    // Check character count
    if (
      state.QRdataExtraField1.length > 2000 ||
      state.QRdataExtraField2.length > 1250 ||
      state.QRdataExtraField3.length > 1250 ||
      state.QRdataExtraField4.length > 1250 ||
      state.QRdataExtraField5.length > 1250 ||
      state.QRdataExtraField6.length > 1250 ||
      state.QRdataExtraField7.length > 1250 ||
      state.QRdataExtraField8.length > 1250 ||
      state.QRdataExtraField9.length > 1250 ||
      state.QRdataExtraField10.length > 1250
    ) return triggerSnackbar("Character count too long. Please shorten your input.", "error");

    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    const formData = {};
    formData.userId = state.user?.id ? state.user.id : 0;
    formData.userHash = state.user?.hash ? state.user.hash : 0;
    formData.QRtype = state.QRtype;
    formData.QRredirectURL = state.QRredirectURL;
    formData.QRdata = state.QRdata;
    formData.QRdataPoplCard = isPoplCard;
    formData.QRdataExtraField1 = state.QRdataExtraField1;
    formData.QRdataExtraField2 = state.QRdataExtraField2;
    formData.QRdataExtraField3 = state.QRdataExtraField3;
    formData.QRdataExtraField4 = state.QRdataExtraField4;
    formData.QRdataExtraField5 = state.QRdataExtraField5;
    formData.QRdataExtraField6 = state.QRdataExtraField6;
    formData.QRdataExtraField7 = state.QRdataExtraField7;
    formData.QRdataExtraField8 = state.QRdataExtraField8;
    formData.QRdataExtraField9 = state.QRdataExtraField9;
    formData.QRdataExtraField10 = state.QRdataExtraField10;
    formData.QRcolor = state.QRcolor;
    formData.QRcolor2 = state.QRcolor2;
    formData.QRlogo = state.QRlogo;
    // formData.QRlogoData = state.QRlogoData;
    formData.QRborder = state.QRborder;
    formData.QRstyle = state.QRstyle;
    formData.QRanalytics = state.QRanalytics;

    toggleQRloading(true);

    try {
      const res = await axios.post("/api/qrcode/getQRresult", formData, config);

      dispatch({
        type: TOGGLE_QR_GENERATED,
        payload: res.data.data,
        isInCollection: res.data.isInCollection,
        redirect: res.data.redirect,
        id: res.data.id
      });

      return res.data.redirect === "" ? res.data.data : res.data.redirect;
    } catch (err) {
      alert("Server Error - Please refresh");
      console.log(err);
      return state.QRdataExtraField1;
    }
  };

  const onGeneratePoplCard = async (obj, photoFile, bannerFile, cb, cb2) => {
    if (state.user == null || !state?.user?.id) {
      cb2();
      return toggleCreateAcc(true);
    }
    let photo = "";
    let banner = "";

    if (photoFile?.src?.includes("base64")) {
      const result = await fetch(photoFile?.src);
      const blob = await result.blob();
      const file = new File([blob], `${state.user?.id}_${getId(12)}`);
      photo = await uploadImage(file, "photos");
      photo = photo.split("?")[0]
        .split("photos%2F")[1];
    }
    if (bannerFile?.src?.includes("base64")) {
      const result = await fetch(bannerFile.src);
      const blob = await result.blob();
      const file = new File([blob], `${state.user?.id}_${getId(12)}`);
      banner = await uploadImage(file, "banners");
      banner = banner.split("?")[0]
        .split("banners%2F")[1];
    }

    const formData = {};
    formData.profileIds = [state.user.id];
    formData.banner = banner;
    formData.photo = photo;
    formData.name = obj.name;
    formData.description = obj.description;
    formData.jobTitle = obj.jobTitle;
    formData.location = obj.location;
    formData.theme = obj.theme;
    formData.data = obj.data;
    formData.colorLink = "0";
    formData.company = "";
    formData.direct = 0;
    formData.logo = "";
    formData.nickname = "";
    formData.private = 0;
    formData.username = "";

    try {
      const response = await axiosInstance.post("/linksdata", formData);

      if (response.data?.data[0].linkData?.pid) {
        onGenerate(response.data?.data[0].linkData?.pid);
        dispatch({
          type: SET_POPL_CARD,
          payload: response.data?.data[0].linkData,
        });
      }
      cb();
    } catch (err) {
      alert("Server Error - Please refresh");
      console.log(err);
    }

    return;

  };

  const getSavedQRs = async (userId, cb) => {
    try {
      const res = await axios.get(`/api/qrcode/saved/${userId}`);

      console.log(res.data);

      const { saved, logs } = res.data;

      dispatch({
        type: QR_LISTS_LOADED,
        saved: saved,
        logs: logs
      });

      cb();
    } catch (err) {
      alert("Server Error - Please refresh");
      console.log(err);
    }
  };

  const generateAiQR = async (payload, cb, errCb, setMaxQRsMet) => {
    if (state.user == null || !state?.user?.id) {
      return toggleCreateAcc(true, "Account required to create AI QR Codes");
    }
    
    const checkIfEligible = await axios.get(`/api/qrcode/usercount/ai/${state.user.id}`);
    
    if (checkIfEligible?.data?.count > 4 && state?.user?.isPro != "1" && state?.user?.isPro != "2") {
      errCb();
      setMaxQRsMet(true);
      return toggleSubscribePopUp(true);
    }
    
    setMaxQRsMet(false);

    let newURL = state.fromAiQR ? state.QRredirectURL : await onGenerate();

    try {
      let uploadedLinkFile = "";
      let iconFile = state?.aiQRlogoURL || "";

      if (state.aiQRlogoFile?.type && state?.aiQRlogoURL === "") {
        uploadedLinkFile = getId(12);
        iconFile = await uploadImage(
          new File(
            [state.aiQRlogoFile],
            `${state.aiQRlogoFile.name.split(".")[
            state.aiQRlogoFile.name.split(".").length - 1
            ]
            }_qrlogoAI_${uploadedLinkFile}.png`,
            { type: state.aiQRlogoFile.type }
          ),
          ""
        );
      }
      const res = await axiosInstanceQR.post("/queue/new_job", {
        // "mid": userId,
        prompts: payload.prompt,
        negativePromptsUser: payload.negative_prompt,
        creativeScale: payload.creativeScale,
        qrUrl: newURL?.includes("qr.popl") ? newURL : state.QRdataExtraField1,
        logoImgUrl: iconFile,
        rect: payload?.preserveLogo,
        batchSize: payload.batchSize,
        outputSize: payload.size,
        // enhancePrompt: payload.prompt.length > 35 ? 0 : 1,
        enhancePrompt: 1,
        strokeWidth: 20
      });

      dispatch({ type: CHANGE_AI_QR_LOGO_URL, payload: iconFile });

      const res2 = await axiosInstanceQR.post("/queue/get_status", {
        jobId: res.data.task_id
      });

      if (res2.data?.task_status === "FAILURE") {
        if (res2.data?.task_result?.error?.includes("prompt_embeds")) {
          alert("Prompt issue. Keep trying or adjust your prompt if the issue persists. This is a known issue with v1.")
        }
        return errCb();
      }

      cb(
        res.data.task_id,
        res2.data.total_queue_size === 0 ? 1 : res2.data.total_queue_size,
        res2.data.queue_postition === 0 ? 1 : res2.data.queue_postition,
        (res2.data.total_queue_size === 0 ? 1 : res2.data.total_queue_size) * 42
      );
    } catch (err) {
      alert(`Server Error - Please refresh - The server could be overloaded, or there could be an error with your input(s)`);
      errCb();
      // alert("Server Error - Please refresh");
      console.log(err);
    }
  };

  const getAiQRinQueue = async (taskId, successCb, updateCb) => {
    try {
      const res = await axiosInstanceQR.post("/queue/get_status", {
        jobId: taskId
      });

      if (res.data?.task_status === "SUCCESS") {
        dispatch({
          type: AI_QR_LOADED,
          payload: res.data?.task_result?.images
        });
        return successCb();
      }
      
      if (res.data?.task_status === "FAILURE") {
        if (res.data?.task_result?.error?.includes("prompt_embeds")) {
          alert("Prompt issue. Keep trying or adjust your prompt if the issue persists. This is a known issue with v1.")
        }
        return successCb();
      }

      updateCb(
        res.data.total_queue_size === 0 ? 1 : res.data.total_queue_size,
        res.data.queue_postition,
        );
        
      } catch (err) {
      alert(`Error, please try again - If the issue persists, please try another prompt`);
      successCb();
      console.log(err);
    }
  };

  const getQRDataByid = async id => {
    try {
      const res = await axios.get(`/api/qrcode/id/${id}`);

      const { data, userId, qrId } = res.data;

      if (data && qrId && data !== "Invalid" && userId != 0) {
        try {
          const response = await axiosInstanceApp.post("/analytics/pop", {
            "mid": userId,
            "activeProfile": 0,
            "urlKey": `qrg${qrId}`
          });

          console.log(response);
        } catch (error) {
          console.log(error)
        }
      }
      dispatch({
        type: QR_LOADED,
        payload: data
      });
    } catch (err) {
      alert("Server Error - Please refresh");
      console.log(err);
    }
  };

  const setSingleQR = async (id, cb = () => { }) => {
    try {
      const res = await axios.get(`/api/qrcode/id/single/${id}`);

      const { data } = res.data;
      console.log(res.data);

      if (data == "Invalid") {
        return history.push("/collections/all");
      }

      dispatch({
        type: SINGLE_QR_LOADED,
        payload: data
      });
      cb();
    } catch (err) {
      history.push("/collections/all");
      alert("Server Error - Please refresh");
      console.log(err);
    }
  };

  const addQRdownload = async (id, type) => {
    try {
      const res = await axios.get(`/api/qrcode/download/${id === "na" ? state.QRid : id}/${type}`);
    } catch (err) {
      alert("Server Error - Please refresh");
      console.log(err);
    }
  };

  const archiveQR = async (id, cb) => {
    try {
      const res = await axios.get(`/api/qrcode/archive/${id}`);
      dispatch({
        type: QR_ARCHIVED,
        payload: id
      });
      cb();
    } catch (err) {
      alert("Server Error - Please refresh");
      console.log(err);
    }
  };

  const editDynamicURL = async (id, newURL, cb) => {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    const formData = {};
    formData.QRid = id;
    formData.newURL = newURL;

    try {
      const res = await axios.post("/api/qrcode/edit-url", formData, config);
      dispatch({
        type: QR_URL_CHANGE,
        payload: id,
        url: newURL
      });
      triggerSnackbar("Successfully changed URL", "success");
      cb();
    } catch (err) {
      alert("Server Error - Please refresh");
      console.log(err);
    }
  };

  const editName = async (id, name, cb) => {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    const formData = {};
    formData.QRid = id;
    formData.name = name;

    try {
      const res = await axios.post("/api/qrcode/edit-name", formData, config);
      dispatch({
        type: QR_NAME_CHANGE,
        payload: id,
        name: name
      });
      triggerSnackbar("QR Code renamed", "success");
      cb();
    } catch (err) {
      alert("Server Error - Please refresh");
      console.log(err);
    }
  };

  const add2collection = async (fromDownload) => {
    if (state.QRinCollection) return;

    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    let uploadedLinkFile = "";
    let iconFile = "";

    if (state.QRlogoFile?.file?.type && state.QRlogo === true) {
      uploadedLinkFile = getId(12);
      iconFile = await uploadImage(
        new File(
          [state.QRlogoFile.file],
          `${state.QRlogoFile.file.name.split(".")[
          state.QRlogoFile.file.name.split(".").length - 1
          ]
          }_qrlogo_${uploadedLinkFile}.png`,
          { type: state.QRlogoFile.file.type }
        ),
        ""
      );
      dispatch({
        type: CHANGE_QR_LOGO_URL,
        payload: iconFile
      });
    }

    const formData = {};
    formData.user = state.user?.id;
    formData.QRid = state.QRid;
    formData.QRcolor = state.QRcolor;
    formData.QRcolor2 = state.QRcolor2;
    formData.QRlogo = state.QRlogo && iconFile !== "";
    formData.QRlogoData = iconFile;
    formData.QRborder = state.QRborder;
    formData.QRstyle = state.QRstyle;
    formData.QRanalytics = state.QRanalytics;

    try {
      dispatch({
        type: QR_LOAD_2_COLLECTION,
        payload: !state.QRtoCollectionLoader
      });

      const res = await axios.post("/api/qrcode/add2collection", formData, config);
      await axios.post("/api/qrcode/slackbot", { type: "Added to saved", payload: state.user.id }, config);

      if (!fromDownload) {
        triggerSnackbar("Added QR Code to collection", "success");
      }

      dispatch({
        type: QR_ADDED_2_COLLECTION
      });


    } catch (err) {
      alert("Server Error - Please refresh");
      console.log(err);
    }
  };

  const editQRCode = async (cb) => {
    console.log(typeof cb);
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    let uploadedLinkFile = "";
    let iconFile = "";

    if (state.QRlogoFile?.file?.type && state.QRlogo === true) {
      uploadedLinkFile = getId(12);
      iconFile = await uploadImage(
        new File(
          [state.QRlogoFile.file],
          `${state.QRlogoFile.file.name.split(".")[
          state.QRlogoFile.file.name.split(".").length - 1
          ]
          }_qrlogo_${uploadedLinkFile}.png`,
          { type: state.QRlogoFile.file.type }
        ),
        ""
      );
      dispatch({
        type: CHANGE_QR_LOGO_URL,
        payload: iconFile
      });
    }

    const formData = {};
    formData.QRid = state.QRid;
    formData.QRcolor = state.QRcolor;
    formData.QRcolor2 = state.QRcolor2;
    formData.QRlogo = state.QRlogo && (iconFile !== "" || state.QRlogoUrl !== "");
    formData.QRlogoData = state.QRlogo && iconFile !== "" ? iconFile : state.QRlogoUrl;
    formData.QRborder = state.QRborder;
    formData.QRstyle = state.QRstyle;
    formData.QRanalytics = state.QRanalytics;

    try {
      dispatch({
        type: QR_LOAD_2_COLLECTION,
        payload: !state.QRtoCollectionLoader
      });

      const res = await axios.post("/api/qrcode/edit", formData, config);

      dispatch({
        type: QR_ADDED_2_COLLECTION
      });

      if (typeof cb === "function") {
        cb();
      }

    } catch (err) {
      alert("Server Error - Please refresh");
      console.log(err);
    }
  };

  const editQRCodeNames = async (qid, title, desc, cb) => {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    const formData = {};
    formData.QRid = qid;
    formData.title = title;
    formData.desc = desc;

    try {
      const res = await axios.post("/api/qrcode/edit-names", formData, config);
      dispatch({
        type: QR_NAME_CHANGE,
        payload: qid,
        name: title,
        desc: desc
      });
      cb();
    } catch (err) {
      alert("Server Error - Please refresh");
      console.log(err);
    }
  };

  const changeQRlogo = async (data) => {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    const formData = {};
    formData.QRlogoData = state.QRlogoData;

    try {
      const response = await setQrLogo(state.QRid, data);
      console.log(response);

      if (response === "fail") {
        triggerSnackbar("Failed to upload", "warning");
      }
      if (response === "server fail") {
        triggerSnackbar("Server Error, please refresh", "error");
      }

      // const res = await axios.post("/api/qrcode/update-logo", formData, config);

      dispatch({ type: CHANGE_QR_LOGO, payload: data });

    } catch (err) {
      alert("Server Error - Please refresh");
      console.log(err);
    }
  };

  const changeQRlogoFile = file => {
    dispatch({ type: CHANGE_QR_LOGO_FILE, payload: file });
  };

  const changeAiQRlogoFile = file => {
    dispatch({ type: CHANGE_AI_QR_LOGO_FILE, payload: file });
  };

  const changeAiQRlogoUrl = str => {
    dispatch({ type: CHANGE_AI_QR_LOGO_URL, payload: str });
  };

  const clearAiQRs = () => {
    dispatch({ type: AI_QR_CLEARED });
  };

  const changeQRtype = type => {
    dispatch({ type: CHANGE_QR_TYPE, payload: type === "Other" ? "" : type });
  };

  const changeQRbackground = color => {
    dispatch({ type: CHANGE_QR_BACKGROUND, payload: color });
  };

  const changeQRcolor = color => {
    dispatch({ type: CHANGE_QR_COLOR, payload: color });
  };

  const changeQRcolor2 = color => {
    dispatch({ type: CHANGE_QR_COLOR2, payload: color });
  };

  const changeQRborder = type => {
    dispatch({ type: CHANGE_QR_BORDER, payload: type });
  };

  const changeQRstyle = type => {
    dispatch({ type: CHANGE_QR_STYLE, payload: type });
  };

  const toggleQRlogo = () => {
    if (!state.QRgenerated) {
      return triggerSnackbar("Generate a QR Code before adding an image", "warning");
    }
    dispatch({ type: TOGGLE_QR_LOGO, payload: !state.QRlogo });
  };

  const changeQRdata = data => {
    dispatch({ type: CHANGE_QR_DATA, payload: data.replace(" ", "") });
  };

  const changeQRdataExtraField = (number, data, allowSpace) => {
    dispatch({
      type: CHANGE_QR_DATA_EXTRA_FIELD,
      number: number,
      payload: allowSpace ? data : data.replace(" ", "")
    });
  };

  const getPops7 = async () => {
    try {
      const dateTo = moment().add(1, "d").format("YYYY-MM-DD");
      const dateFrom = moment().subtract(6, "d").format("YYYY-MM-DD");

      const response = await axiosInstanceV3(`/analytics/pops?pagination=false&fromDate=${dateFrom}&toDate=${dateTo}&includeTrend=true&profileIds=${state.user.id}&includeDeactivatedProfile=false&includePendingProfile=false&timezone=America/Los_Angeles`, {
        method: "GET",
        header: {
          Authorization: localStorage.getItem("@qrToken"),
        }
      }).then((res) => {
        dispatch({
          type: GET_LAST_7_DAYS,
          payload: res.data.data,
        });
      });

    } catch (error) {
      alert("error getting pops");
      console.log(error);
    }
  };

  const getPops30 = async () => {
    try {
      const dateTo = moment().add(1, "d").format("YYYY-MM-DD");
      const dateFrom = moment().subtract(30, "d").format("YYYY-MM-DD");

      const response = await axiosInstanceV3(`/analytics/pops?pagination=false&fromDate=${dateFrom}&toDate=${dateTo}&includeTrend=true&profileIds=${state.user.id}&includeDeactivatedProfile=false&includePendingProfile=false&timezone=America/Los_Angeles`, {
        method: "GET",
        header: {
          Authorization: localStorage.getItem("@qrToken"),
        }
      }).then((res) => {
        dispatch({
          type: GET_LAST_30_DAYS,
          payload: res.data.data,
        });
      });

    } catch (error) {
      alert("error getting pops");
      console.log(error);
    }
  };

  const getPops365 = async () => {
    try {
      const dateTo = moment().add(1, "d").format("YYYY-MM-DD");
      const dateFrom = moment().subtract(365, "d").format("YYYY-MM-DD");

      const response = await axiosInstanceV3(`/analytics/pops?pagination=false&fromDate=${dateFrom}&toDate=${dateTo}&includeTrend=true&profileIds=${state.user.id}&includeDeactivatedProfile=false&includePendingProfile=false&timezone=America/Los_Angeles`, {
        method: "GET",
        header: {
          Authorization: localStorage.getItem("@qrToken"),
        }
      }).then((res) => {
        dispatch({
          type: GET_LAST_365_DAYS,
          payload: res.data.data,
        });
      });

    } catch (error) {
      alert("error getting pops");
      console.log(error);
    }
  };

  const getPopsAll = async () => {
    console.log(state.user);
    try {
      const dateTo = moment().add(1, "d").format("YYYY-MM-DD");
      const dateFrom = moment(state.user.registeredDate).format("YYYY-MM-DD");

      const response = await axiosInstanceV3(`/analytics/pops?pagination=false&fromDate=${dateFrom}&toDate=${dateTo}&includeTrend=true&profileIds=${state.user.id}&includeDeactivatedProfile=false&includePendingProfile=false&timezone=America/Los_Angeles`, {
        method: "GET",
        header: {
          Authorization: localStorage.getItem("@qrToken"),
        }
      }).then((res) => {
        dispatch({
          type: GET_ALL_POPS,
          payload: res.data.data,
        });
      });

    } catch (error) {
      alert("Error getting scan data");
      console.log(error);
    }
  };

  const getPopsForComparer = async (days, idsOrHashes) => {
    try {
      const dateTo = moment().add(1, "d").format("YYYY-MM-DD");
      const dateFrom = moment().subtract(days, "d").format("YYYY-MM-DD");

      const response = await axiosInstanceV3(`/analytics/specific_pops?fromDate=${dateFrom}&toDate=${dateTo}&urlKey=${idsOrHashes}&timezone=America/Los_Angeles`, {
        method: "GET",
        header: {
          Authorization: localStorage.getItem("@qrToken"),
        }
      }).then((res) => {
        dispatch({
          type: GET_COMPARER_POPS,
          payload: res.data.data,
        });
      });

    } catch (error) {
      alert(`error getting pops for comparer ${error}`);
      console.log(error);
    }
  };

  // Stripe

  // rev cat
  const requestMakePro = (id) => {
    const makeProData = new FormData();
    makeProData.append("sAction", "AddPro");
    makeProData.append("sId", id);
    return axios.post("https://dash.popl.co/api", makeProData);
  };

  const createSubscription = async ({
    customerId,
    paymentMethodId,
    priceId,
    successPaymentCb,
    errorPaymentCb,
    googleApplePayEvent,
  }) => {
    try {
      const body = {
        customer: customerId,
        paymentMethodId,
        priceId,
      };
      body.trialPeriodDays = 14;
      body.isPro = parseInt("1");

      const { data } = await axiosInstanceV3.post(`/stripe/subscription/pro`, body);

      const subItem = data.subscription.items.data.find((item) => item.price.id === priceId);
      // setting pro/proPlus in revenue cat
      setTimeout(() => requestMakePro(state?.user?.id), 5000);
      // if (dashboardPlan == 2) setTimeout(() => requestMakeProPlus([userId]), 5000);
      successPaymentCb(`${subItem.id}%${data.subscription.id}`);
      if (googleApplePayEvent) {
        googleApplePayEvent.complete("success");
      }
    } catch (error) {
      console.log(error);
      errorPaymentCb(error.response?.data?.message);
      googleApplePayEvent && googleApplePayEvent.complete("fail");
    }
  };

  const createCustomer = async (billingInfo, setCustomer) => {
    try {
      const body = {
        email: state?.user?.email,
      };
      if (billingInfo?.zipCode) body.postal = billingInfo.zipCode;
      if (billingInfo?.country) body.country = billingInfo.country;

      let response = await axiosInstanceV3.post("/stripe/customer", body);

      if (response.data.customer.id) {
        setCustomer(response.data.customer.id);
        dispatch({
          type: SET_STRIPE_CUSTOMER_NA,
          payload: response.data.customer.id,
        })
        return response.data.customer;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateCustomer = async (billingInfo, customerId) => {
    try {
      const body = {
        email: state?.user?.email,
        postal: billingInfo.zipCode,
        country: billingInfo.country,
      };

      return axiosInstanceV3.put(`/stripe/customer/${customerId}`, body);
    } catch (error) {
      console.log(error);
    }
  };

  const setStripeCustomerAction = (customer) => (
    dispatch({
      type: SET_STRIPE_CUSTOMER_NA,
      payload: customer,
    })
  );

  const setProSuccess = () => (
    dispatch({
      type: SET_PRO_SUCCESS
    })
  );

  const toggleFromAiQR = (bool) => (
    dispatch({
      type: TOGGLE_FROM_AI_BTN,
      payload: bool
    })
  );

  // End Stripe

  const queryDbByDate = async (date) => {
    try {
      const res = await axios.get(`/api/qrcode/date/${date}`);
      const { total } = res.data;
      alert(total);
    } catch (err) {
      alert("Server Error - Please refresh");
      console.log(err);
    }
  };

  const createBlog = async (blog, cb) => {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    const formData = {};
    formData.blog = blog;
    formData.userId = state.user?.id ? state.user.id : 0;

    try {
      const res = await axios.post("/api/qrcode/blog", formData, config);
      triggerSnackbar("Blog uploaded", "success");
      cb();
    } catch (err) {
      alert("Server Error - Please refresh");
      console.log(err);
    }
  };

  const getSavedBlogs = async (cb) => {
    try {
      const res = await axios.get(`/api/qrcode/blogs`);

      console.log(res.data);

      const { blogs } = res.data;

      dispatch({
        type: BLOGS_LOADED,
        blogs: blogs,
      });

      cb();
    } catch (err) {
      alert("Server Error - Please refresh");
      console.log(err);
    }
  };

  const clearPoplCard = async () => {
    dispatch({
      type: CLEAR_POPL_CARD,
    });
  };

  return (
    <mainContext.Provider
      value={{
        user: state.user,
        snackbar: state.snackbar,
        stripeCustomer: state.stripeCustomer,
        last7Data: state.last7Data,
        last30Data: state.last30Data,
        last365Data: state.last365Data,
        allTimeData: state.allTimeData,
        comparerData: state.comparerData,
        subscribePopUp: state.subscribePopUp,
        viewScanpopup: state.viewScanpopup,
        contactPopUp: state.contactPopUp,
        createAccPopUp: state.createAccPopUp,
        createAccPopUpMessage: state.createAccPopUpMessage,
        savedQRs: state.savedQRs,
        QRloading: state.QRloading,
        QRgenerated: state.QRgenerated,
        QRinCollection: state.QRinCollection,
        QRisSavable: state.QRisSavable,
        QRtoCollectionLoader: state.QRtoCollectionLoader,
        QRid: state.QRid,
        QRtypeOptions: state.QRtypeOptions,
        QRtype: state.QRtype,
        QRbackground: state.QRbackground,
        QRcolor: state.QRcolor,
        QRcolor2: state.QRcolor2,
        QRborder: state.QRborder,
        QRstyle: state.QRstyle,
        QRanalytics: state.QRanalytics,
        QRlogo: state.QRlogo,
        QRlogoData: state.QRlogoData,
        QRlogoFile: state.QRlogoFile,
        aiQRlogoFile: state.aiQRlogoFile,
        aiQRlogoURL: state.aiQRlogoURL,
        QRlogoUrl: state.QRlogoUrl,
        QRredirectURL: state.QRredirectURL,
        QRdata: state.QRdata,
        QRdataExtraField1: state.QRdataExtraField1,
        QRdataExtraField2: state.QRdataExtraField2,
        QRdataExtraField3: state.QRdataExtraField3,
        QRdataExtraField4: state.QRdataExtraField4,
        QRdataExtraField5: state.QRdataExtraField5,
        QRdataExtraField6: state.QRdataExtraField6,
        QRdataExtraField7: state.QRdataExtraField7,
        QRdataExtraField8: state.QRdataExtraField8,
        QRdataExtraField9: state.QRdataExtraField9,
        QRdataExtraField10: state.QRdataExtraField10,
        singleQR: state.singleQR,
        fromAiQR: state.fromAiQR,
        poplCardData: state.poplCardData,
        optionalDownload: state.optionalDownload,
        blogs: state.blogs,
        aiQRs: state.aiQRs,
        triggerSnackbar,
        authHandler,
        logout,
        setTokens,
        getUserProfileAction,
        signIn,
        signUp,
        triggerSlack,
        onGenerate,
        onGeneratePoplCard,
        toggleQRloading,
        clearAiQRs,
        changeQRtype,
        changeQRbackground,
        changeQRcolor,
        changeQRcolor2,
        changeQRborder,
        changeQRstyle,
        toggleQRlogo,
        changeQRlogo,
        changeQRlogoFile,
        changeAiQRlogoFile,
        changeAiQRlogoUrl,
        changeQRdata,
        changeQRdataExtraField,
        getQRDataByid,
        generateAiQR,
        getAiQRinQueue,
        setSingleQR,
        getSavedQRs,
        toggleFromAiQR,
        editQRCode,
        editQRCodeNames,
        add2collection,
        addQRdownload,
        toggleCreateAcc,
        toggleSubscribePopUp,
        toggleViewScanPopUp,
        toggleContactPopUp,
        sendMessage,
        archiveQR,
        editDynamicURL,
        editName,
        getPops7,
        getPops30,
        getPops365,
        getPopsAll,
        getPopsForComparer,
        createSubscription,
        createCustomer,
        updateCustomer,
        setStripeCustomerAction,
        setProSuccess,
        queryDbByDate,
        createBlog,
        getSavedBlogs,
        clearPoplCard
      }}
    >
      {props.children}
    </mainContext.Provider>
  );
};

export default MainState;
