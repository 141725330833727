import React, { useState, useContext, useEffect, useRef } from "react";
import logo from "../cod/img/PoplSpokesAnimated2.svg";
import logoType from "../cod/img/logoType.png";
import { Link } from "react-router-dom";
import MainContext from "../context/main/mainContext";
import Button from "@material-ui/core/Button";
import { TweenMax, Power3 } from "gsap";
import { styled } from "@material-ui/core/styles";

const InputButton = styled(Button)(() => ({
  color: "#ffffff",
  backgroundColor: "#5403fe",
  "&:hover": {
    backgroundColor: "#24a700"
  },
  textTransform: "none",
  fontSize: 13,
  fontWeight: 800,
  padding: "5px 20px",
  fontFamily: "Montserrat, sans-serif",
  float: "right"
}));

const Create = props => {
  document.title = "QR by Popl | Create an Account";
  const mainContext = useContext(MainContext);
  const {
    user,
  } = mainContext;

  const [userState, setUserState] = useState({
    email: "",
    password: "",
    password2: "",
    showPassword: false
  });

  const onChange = e => {
    setUserState({ ...userState, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (user) {
      props.history.push("/dashboard/app");
    }
  }, [user]);

  let logoItem = useRef(null);

  useEffect(() => {
    if (logoItem) {
      TweenMax.from(logoItem, 2, {
        opacity: 1,
        x: -30,
        ease: Power3.easeOut
      });
    }
  }, []);

  return (
    <div className="authContainer">
      <div className="authNav">
        <Link to="/" style={{ textDecoration: 'none' }}>
          <div
            ref={x => {
              logoItem = x;
            }}
            className="leftNavAuth"
          >
            <img src={logo} />
            <h1>
              QR Code
              <br />
              Generator
            </h1>
            <div>
              <p>by</p>
              <img src={logoType} />
            </div>
          </div>
        </Link>
      </div>
      <div className="popupContainerAuth">
        <div className="createAccountPopUp">

        </div>
        <div className="signInText">
          <p>Login here</p>
        </div>
      </div>
      <div className="footerAuth">

      </div>
    </div>
  );
};

export default Create;
