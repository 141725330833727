import React, { Suspense, lazy, useContext, useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./cod/styles/App.css";
import history from "./utilities/history";
import MainState from "./context/main/mainState";
import ScrollToTop from "react-router-scroll-top";
import Spinner from "./utilities/Spinner";
import RedirectQR from "./redirect/RedirectQR";
import PrivateRoute from "./routing/PrivateRoute";
import Create from "./login/Create";

const Home = lazy(() => import("./Home"));
const Collections = lazy(() => import("./collections-page/Collections"));
const Blog = lazy(() => import("./blog/Blog"));

const App = () => {
  return (
    <MainState>
        <BrowserRouter history={history}>
          <ScrollToTop>
            <Suspense fallback={<Spinner />}>
              <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/:qrID" exact component={RedirectQR} />
                <Route path="/blog/posts/:id" exact component={Blog} />
                <Route path="/blog/all" exact component={Blog} />
                <PrivateRoute path="/collections/all" exact component={Collections} />
              </Switch>
            </Suspense>
          </ScrollToTop>
        </BrowserRouter>
    </MainState>
  );
};

export default App;
